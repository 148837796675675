.loaderContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 100%;
  }
}
