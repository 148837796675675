@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h4 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    /* margin-top: 79px; */
    /* margin-left: 60px; */
    border-radius: 30px;
    /* padding: 30px; */
    padding: 30px;
    border: 1px solid #d9d9d9;
    background: #fff;
    /* padding-left: 60px; */

    /* The calendar needs 312px */
    /* flex-basis: 312px;
    flex-shrink: 0; */
  }
  & > div {
    & > div {
      margin-top: 0 !important;
      /* @media(max-width:1199px){
        background: none !important;
      } */
    }
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -72px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -112px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */
  width: 152px;
  height: 152px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 31px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionDescription {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 36px;
  }
}

.descriptionTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  padding: 0 0px;
  margin-bottom: 35px;
  max-height: 400px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}
/* 
.map {
  height: 320px !important;
  width: 100%;
  max-width: 640px;
  max-height: 320px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 320px;
  }
  & > div {
    & > div:nth-child(3) {
      & > div {
        z-index: 0 !important;
      }
    }
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
} */

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.lrmain {
  /* background-color: #fafafa; */
}
.maindv {
  margin: 40px auto 0;
  margin-bottom: 80px;
  /* width: 90%; */
  width: 90vw;
  max-width: 2000px;
  /* background-color: #fafafa; */
  @media (max-width: 1199px) {
    width: 100%;
    padding: 0 15px;
    margin: 40px 0;
  }
  /* @media (max-width: 991px) {
    padding: 0 30px;
  } */
  @media (max-width: 767px) {
    /* padding: 0 15px; */
    max-width: 100%;
  }
}
.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #000;
  display: block;
  margin: 0 8px;
  flex-shrink: 0;
  @media (max-width: 991px) {
    margin: 5px;
  }
}
.infos {
  display: flex;
  align-items: center;
  margin-top: 25px;
  flex-wrap: wrap;
  & svg {
    height: 22px;
    width: 22px;
    fill: #fff;
    margin-right: 10px;
  }
  & .info:last-child {
    &::after {
      display: none;
    }
  }
  @media (max-width: 991px) {
    row-gap: 10px;
  }
  @media (max-width: 575px) {
    margin-top: 10px;
  }
}
.info {
  white-space: nowrap;
  font-size: 15px;
  color: #000;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
  position: relative;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.info::after {
  /* position: absolute; */
  display: block;
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #000;
  margin-left: 10px;
}
.secdvhead {
  display: flex;
}
.secdvhead > span {
  display: flex;
  align-items: center;
  @media (max-width: 991px) {
    align-items: flex-start;
    flex-direction: column;
  }
  @media (max-width: 767px) {
    row-gap: 5px;
  }
}
.headmndv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  @media (max-width: 767px) {
    flex-direction: column;
    row-gap: 10px;
    align-items: flex-start;
  }
}
.headmndv > div:first-child {
  & p:first-child {
    font-size: 36px;
    font-weight: 700;
    color: #292929;
    @media (max-width: 991px) {
      font-size: 25px;
    }
    @media (max-width: 575px) {
      font-size: 23px;
    }
  }
}
.headmndv > div:last-child {
  & span:first-child {
    & svg {
      fill: #fff;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      @media (max-width: 767px) {
        width: 15px;
        height: 15px;
      }
    }
  }
  & span:first-child {
    margin-left: 0 !important;
  }
  & span:last-child {
    margin-right: 0 !important;
  }
  & > span {
    margin: 0 0 0 10px;
    text-decoration: underline;
    font-size: 18px;
    font-weight: 600;
    color: #292929;
    cursor: pointer;
    @media (max-width: 1280px) {
      margin: 0 0 0 12px;
    }
    @media (max-width: 1199px) {
      margin: 0 0 0 12px;
    }
    @media (max-width: 991px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      margin: 0 10px 0 0;
    }
  }
  & button {
    border: none;
    padding: 0;
  }
  & svg {
    /* margin-right: 5px; */
    display: inline-block;
    fill: none;
    @media (max-width: 767px) {
      width: 15px;
      height: 15px;
    }
  }
}
.shareIcon {
  fill: #fe5f50;
  width: 25px;
  height: 30px;
}
.slckimg {
  /* width: 100%; */
  margin: auto;
  width: 100%;
  height: 300px;
  /* object-fit: cover; */
  object-fit: cover;
  background-color: #000;
  /* background-color: var(--marketplaceColorNoResult); */
  aspect-ratio: 1;
  @media (max-width: 1199px) {
    width: 100%;
  }
  @media (max-width: 575px) {
    /* height: 190px; */
    border-radius: 20px;
    /* width:auto; */
    aspect-ratio: inherit;
  }
}
/* .slckimg:empty {
  cursor: progress;
  background: linear-gradient(0.25turn, transparent, #fff, transparent), linear-gradient(#eee, #eee),
    radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
    linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  background-size: 200% 100%;
  animation: loading 1.5s ease-in-out infinite;
} */
.imageLoading {
  width: 680px;
  height: 300px;
  object-fit: cover;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  display: inline-block;
  animation: loading 1.5s ease-in-out infinite;
}

@keyframes loading {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.bodydv {
  width: 100%;
}
.bodydv > div:first-child {
  max-width: 57%;
  flex-basis: 57%;
  @media (max-width: 1024px) {
    max-width: 50%;
    flex-basis: 50%;
  }
  @media (max-width: 1023px) {
    max-width: 100%;
    flex-basis: 100%;
  }
}
.bodydv {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & > div:nth-child(2) {
    z-index: 1;
    /* position:fixed; */
  }
  @media (max-width: 1023px) {
    display: block;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.bodydv > div:last-child {
  max-width: 40%;
  flex-basis: 40%;
  position: sticky;
  top: 74px;
  @media (max-width: 1024px) {
    max-width: 48%;
    flex-basis: 48%;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    flex-basis: 100%;
  }
}
.authsec img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.authsec {
  display: flex;
  margin-top: 25px;
}
.authdet {
  margin-left: 10px;
  display: flex;
  /* flex-direction: column; */
  flex-direction: row;
  justify-content: center;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.authdet > p:first-child {
  margin: 0;
  color: #767676;
  font-size: 14px;
  line-height: 18px;
}
.authdet > p:last-child {
  /* margin-top: 5px; */
  display: flex;
  align-items: center;
  line-height: 18px;
  margin-bottom: 0;
  & span:first-child {
    color: #111111;
    font-size: 16px;
    margin-right: 15px;
  }
  & span:nth-child(2) {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  & span:nth-child(3) {
    margin-right: 10px;
  }
  & svg {
    fill: #fe5f50;
    margin-right: 3px;
  }
  & span:last-child {
    font-size: 14px;
    /* text-decoration: underline; */
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.headdv {
}
.headdv > p:first-child {
  margin: 0;
  text-decoration: underline;
  color: #fe5f50;
  font-weight: 600;
}
.reviewtopsec {
  font-size: 14px;
  text-decoration: underline;
  margin-right: 25px;
  color: #000;
  & svg {
    width: 25px;
    height: 30px;
    fill: #fe5f50;
    margin-right: 3px;
  }
}
.loctopsec {
  font-size: 14px;
  color: #4e4e4e;
  margin-right: 25px;
  display: flex;
  margin-bottom: 10px;

  & span:nth-child(2) {
    text-decoration: underline;
  }
  & span:last-child {
    text-decoration: none;
  }
  & svg {
    width: 16px;
    height: 20px;
    margin-right: 3px;
    & path {
      stroke: #4e4e4e;
    }
  }
  @media (max-width: 991px) {
    line-height: 24px;
  }
  @media (max-width: 767px) {
    margin-right: 0;
  }
}

.mapLoc {
  margin-right: 5px;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.descdv {
  margin-top: 25px;
  & p:first-child {
    margin: 0 0 20px;
  }
  & p:last-child {
    margin: 20px 0 0;
    word-break: break-all;
  }
  & p {
    font-size: 15px;
    line-height: 25px;
    color: #292929;
    font-weight: 400;
    @media (max-width: 575px) {
      font-size: 14px;
    }
  }
  & button {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    border: 0;
    padding: 0;
  }
}
.similardv label,
.rvwdv label,
.amentiesdv label,
.locationdv label,
.rulesdv label {
  color: #000;
  font-size: 18px;
  font-weight: 700;
}
.similardv {
  margin-top: 35px;
  & label {
    margin: 15px 0 !important;
  }
  & > div {
    & > div {
      @media (max-width: 1199px) {
        min-height: 480px;
      }
      @media (max-width: 991px) {
        min-height: 440px;
      }
      /* @media(max-width:575px){
        min-height: 590px;
      } */
      & > div {
        & > div:nth-child(2) {
          @media (max-width: 1199px) {
            min-height: 250px;
          }
          @media (max-width: 767px) {
            min-height: 220px;
          }
          @media (max-width: 575px) {
            min-height: 200px;
          }
        }
      }
    }
  }
}
.amentiesdv,
.locationdv,
.rulesdv {
  margin-top: 25px;
}
.amentiesdv > div {
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  & div {
    flex-basis: 40%;
    max-width: 40%;
    margin: 0 0 10px;
    & svg {
      width: 25px;
      height: auto;
    }
    & span {
      font-size: 15px;
      font-weight: 500;
      color: #000;
      margin-left: 10px;
    }
    @media (max-width: 575px) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}
.rulesdv .dshd {
  font-size: 15px;
  line-height: 25px;
  color: #292929;
  font-weight: 400;
  /* height: 118px; */
  overflow-y: hidden;
  & > p {
    @media (max-width: 575px) {
      margin-bottom: 0;
    }
    & > span {
      font-family: 'poppins' !important;
      font-size: 16px !important;
      font-weight: 500 !important;
      color: #292929 !important;
    }
  }
  @media (max-width: 767px) {
    height: auto;
  }
}
.rulesdv {
  /* height: 290px !important; */
}
/* .rulesdv .seeFull {
  height: unset !important;
} */
.rulesdv {
  & ul {
    margin-left: 17px;
  }
  & ul li {
    list-style-type: disc;
    font-size: 15px;
    line-height: 25px;
    color: #292929;
    font-weight: 400;
  }
  & button {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin-top: 5px;
  }
}
.echrvw {
  margin: 20px 0;
  & img {
    margin-top: 3px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
.rvwinfo > div {
  display: flex;
  align-items: center;
  color: #b2b2b2;
  font-size: 14px;
  line-height: 20px;
}
.rvwdv {
  margin-top: 25px;
  & button {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    border: 0;
    padding: 0;
  }
}
.echrvw {
  display: flex;
}
.echrvw > div:first-child {
  margin-right: 13px;
}
.echrvw p {
  margin: 0 0 7px;
  font-size: 15px;
  line-height: 23px;
  color: #292929;
  font-weight: 400;
}
.listingCard {
  margin: 0 0 74px 0;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
  padding: 10px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #e8e8e8;
  overflow: hidden;

  /* for class "imageDetailRow" */
  & > div:nth-child(1) {
    display: flex;
    flex-direction: column !important;

    /* for class ".ListingCard_threeToTwoWrapper" */
    & > div:nth-child(1) {
      width: 100%;
      margin-bottom: 20px;
    }

    /* for class ".ListingCard_info" */
    & > div:nth-child(2) {
      margin-left: 0;

      /* for class ".ListingCard_mainInfo" */
      & > div:nth-child(1) {
        text-transform: capitalize;
        margin-bottom: 9px;
        /* for class "ListingCard_title" */
        & > div:nth-child(1) {
          font-size: 18px;
          font-weight: 700;
          @media (max-width: 1199px) {
            font-size: 16px;
          }
          @media (max-width: 991px) {
            flex-direction: column;
          }
          @media (max-width: 767px) {
            flex-direction: row;
            justify-content: space-between;
            font-size: 16px;
            margin-bottom: 10px;
          }
          /* text-transform: capitalize; */
        }

        /* for class "ListingCard_srttxt" */
        & > div:nth-child(2) {
          & > span {
            font-size: 16px !important;
            font-weight: 600 !important ;
          }

          & span {
            font-size: 16px !important;
            font-weight: 400;
            line-height: 24px;
          }
        }

        & > div:nth-child(3) {
          & span {
            font-size: 16px !important;
          }
        }
      }

      /* for class "ListingCard_price" */
      & > div:nth-child(2) {
        margin-top: 0;
        & > div {
          font-size: 18px;
          font-weight: 600;
        }
        & > div:nth-child(2) {
          color: #7b7b7b;
          font-weight: 400;
        }
        @media (max-width: 575px) {
          margin-top: 0;
        }
      }
    }
  }
  @media (max-width: 767px) {
    margin: 0 0 40px 0;
  }
}
.listingsContainer {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 32%);
  grid-gap: 10px;

  @media (min-width: 992px) and (max-width: 1223.98px) {
    grid-template-columns: repeat(auto-fill, 31%);
  }
  @media (max-width: 991px) {
    grid-template-columns: repeat(auto-fill, 32%);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }

  @media (min-width: 1224px) {
    grid-template-columns: repeat(auto-fill, 31%);
  }
  /* @media (min-width: 1400px) {
    grid-template-columns: repeat(auto-fill, 22%);
  } */
}
.slimglispagsli {
  :global(.slick-arrow) {
    width: 30px !important;
    height: 30px !important;
    background: #fff;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
  }
}
.oneimgcls {
  :global(.slick-track) {
    @media (max-width: 575px) {
      width: 100% !important;
    }
    & > div {
      @media (max-width: 575px) {
        width: 100% !important;
      }
    }
  }
}
.slimglispagsli {
  :global(.slick-next:before),
  :global(.slick-prev:before) {
    /* color: #fe5f50 !important; */
    color: rgb(0 0 0 / 73%) !important;
    font-family: FontAwesome;
    font-size: 18px;
    @media (max-width: 575px) {
      font-size: 16px;
    }
  }
  :global(.slick-next) {
    width: auto;
    height: auto;
    right: 2px;
    &:hover {
      color: #fff;
    }
    &:focus {
      color: #fff;
    }
  }
  :global(.slick-prev) {
    width: auto;
    height: auto;
    left: 2px;
    z-index: 1;
    &:hover {
      color: #fff;
    }
    &:focus {
      color: #fff;
    }
  }
  :global(.slick-prev:before) {
    /* color: #fe5f50 !important; */
    /* color: #000 !important; */
    content: '\f104';
    /* color: #fff;
    box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.2);
    opacity: 1;
    font-size: 30px;
    @media (max-width: 575px) {
      font-size: 25px;
    } */
  }
  :global(.slick-next:before) {
    content: '\f105';
  }
  :global(.slick-slide) {
    /* background-color: #000 !important; */
    @media (max-width: 575px) {
      background-color: transparent !important;
    }
  }
  flex: 0 0 auto;
  border-radius: 20px;
  height: 300px;
  overflow: hidden;
  @media (max-width: 575px) {
    /* height: auto; */
  }
  & > div {
    & > div {
      & > div {
        & > div {
          & > div {
            & > div {
              & img {
                @media (max-width: 575px) {
                  border-radius: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.slckimgdv {
  /* padding: 0 5px; */
  padding: 0;
  /* width: 100%; */
  width: 680px !important;
  height: 100%;
  background: #fff;
  overflow: hidden;
  @media (min-width: 2500px) {
    width: 1200px !important;
  }
  @media (min-width: 1400px) {
    width: 1000px !important;
  }
  @media (max-width: 991px) {
    width: 860px !important;
  }
  @media (max-width: 575px) {
    width: 100% !important;
  }
}
.onePlusImg {
  padding-inline: 5px;
}
.catfilt {
  margin-bottom: 20px;
  display: flex;
  & button:last-child {
    margin-right: 0 !important;
  }
  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 10px;
  }
}
.actctbtn {
  border: 1px solid #fe5f50 !important;
  background-color: #fff1f0 !important;
}
.ctflbtn {
  border: 1px solid #dfdfdf;
  background-color: #fff;
  color: #000;
  padding: 3px 25px;
  margin-right: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  font-size: 15px;

  & svg {
    height: 22px;
    width: 22px;
    fill: #fff;
    margin-right: 10px;
  }
  @media (max-width: 767px) {
    padding: 3px 10px;
    font-size: 13px;
    margin-right: 0;
  }
}

.shareDiv {
  position: absolute;
  left: -40px !important;
  background: #fff;
  border-radius: 10px;
  z-index: 5;
  padding: 10px;
  /* border: 1px solid black; */
  filter: drop-shadow(0 2px 5px #0004);
  margin-top: 10px;
  width: 200px;
  /* left: 0 !important; */
  /* @media (max-width: 1023px) {
    z-index: 1;
  } */
  @media (max-width: 575px) {
    right: 10%;
    margin-top: 10px;
    left: 0 !important;
  }

  &:before {
    left: 10%;
    width: 1.5em;
    border: 0.75em solid transparent;
    bottom: 100%;
    height: 1.5em;
    content: '';
    z-index: -1;
    position: absolute;
    transform: scaleY(0.6) translateX(-50%);
    box-sizing: border-box;
    pointer-events: none;
    transform-origin: 100% 100%;
    border-bottom-color: white;
  }
}
.shareIconDiv {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  & svg {
    width: auto;
  }
  @media (max-width: 575px) {
    width: 100%;
  }
}
.linkCopyShare {
  display: flex;
  justify-content: center;
  align-items: center;

  & input {
    border: 1px solid #c7c7c7 !important;
    padding: 0 2px !important;
  }
  & button {
    padding: 1px 0 !important;
  }

  & button {
    flex-basis: 35%;
    background: #6b77ff;
    /* padding: 10px 0px; */
    border-radius: 10px;
    box-shadow: none;
    color: #fff;
    border-radius: 0 6px 6px 0;
    font-size: 14px;
    font-family: Avenir-Next;
  }
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
}
.linkCopyBox {
  flex-basis: 65%;
  text-overflow: ellipsis;
  border: 1px solid #c7c7c7;
  border-radius: 6px 0 0 6px;
  /* box-shadow: none !important; */
  margin: 10px 0;
  padding: 3px 0;
  font-size: 14px;
  font-family: Avenir-Next;
  @media (max-width: 575px) {
    padding: 7px 0;
    flex-basis: 100%;
  }
}
.VenueDtls {
  display: flex;
  align-items: center;
  @media (max-width: 991px) {
    display: flex;
    align-items: baseline;
  }
  @media (max-width: 767px) {
    /* margin-top: 15px; */
  }
}
.locatnWrap {
}
.bookpaneldv {
  & > div {
    & > div {
      & > div {
        justify-content: center;
        align-items: flex-start;
        padding: 0;
        /* background-color: rgba(0, 0, 0, 0.4) !important; */
        background-image: none;
        /* & > div{
          padding:30px;
        } */
      }
    }
  }
}
.favicon {
  border: none;
  border-radius: 50px;
}
.favBtn,
.unfavBtn {
  width: 24px;
  height: 24px;
  fill: none;
  cursor: pointer;
  margin-left: 12px;
}
.favBtn {
  fill: none;
}
.unfavBtn {
  fill: var(--marketplaceColorDark) !important;
}
.reviewWrap {
  /* @media(max-width:767px){
    display:flex;
  } */
  display: flex;
  align-items: center;
  margin-left: 25px;
  @media (max-width: 767px) {
    margin-left: 0;
  }
}
.Namegrp {
  display: flex;
  flex-direction: column;
  & p {
    margin: 0;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #767676;
  }
  & span {
    font-size: 16px;
    font-weight: 600;
    color: #111111;
  }
}

.editIcon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-left: 12px;
}

.shareContainer {
  position: relative;
}

.loaderContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 100%;
  }
}
.address {
  margin-bottom: 20px !important;
}
.descriptionFont {
  font-size: 16px !important;
  line-height: 32px !important;
  font-weight: 500 !important;
}
.addressStyle {
  text-decoration: underline;
}

.locationdv {
  max-width: 100%;
  overflow: hidden;
}
